@import '~react-image-gallery/styles/css/image-gallery.css';
@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-ExtraBold.eot');
    src: url('../public/fonts/OpenSans-ExtraBold.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../public/fonts/OpenSans-ExtraBold.woff') format('woff'),
        url('../public/fonts/OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-Bold.eot');
    src: url('../public/fonts/OpenSans-Bold.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../public/fonts/OpenSans-Bold.woff') format('woff'),
        url('../public/fonts/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-LightItalic.eot');
    src: url('../public/fonts/OpenSans-LightItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('../public/fonts/OpenSans-LightItalic.woff') format('woff'),
        url('../public/fonts/OpenSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-BoldItalic.eot');
    src: url('../public/fonts/OpenSans-BoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../public/fonts/OpenSans-BoldItalic.woff') format('woff'),
        url('../public/fonts/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-Medium.eot');
    src: url('../public/fonts/OpenSans-Medium.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-Medium.woff2') format('woff2'),
        url('../public/fonts/OpenSans-Medium.woff') format('woff'),
        url('../public/fonts/OpenSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-Regular.eot');
    src: url('../public/fonts/OpenSans-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../public/fonts/OpenSans-Regular.woff') format('woff'),
        url('../public/fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-ExtraBoldItalic.eot');
    src: url('../public/fonts/OpenSans-ExtraBoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('../public/fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
        url('../public/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-Italic.eot');
    src: url('../public/fonts/OpenSans-Italic.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-Italic.woff2') format('woff2'),
        url('../public/fonts/OpenSans-Italic.woff') format('woff'),
        url('../public/fonts/OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-SemiBoldItalic.eot');
    src: url('../public/fonts/OpenSans-SemiBoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('../public/fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
        url('../public/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-SemiBold.eot');
    src: url('../public/fonts/OpenSans-SemiBold.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../public/fonts/OpenSans-SemiBold.woff') format('woff'),
        url('../public/fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-MediumItalic.eot');
    src: url('../public/fonts/OpenSans-MediumItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-MediumItalic.woff2') format('woff2'),
        url('../public/fonts/OpenSans-MediumItalic.woff') format('woff'),
        url('../public/fonts/OpenSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-Light.eot');
    src: url('../public/fonts/OpenSans-Light.eot?#iefix')
            format('embedded-opentype'),
        url('../public/fonts/OpenSans-Light.woff2') format('woff2'),
        url('../public/fonts/OpenSans-Light.woff') format('woff'),
        url('../public/fonts/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --color-text-base: rgb(51, 51, 51);
        --color-text-muted: #595959;
        --color-text-inverted: #ffffff;
        --color-fill: #4338ca;
        --color-primary: #707070;
        --color-secondary: #e3001b;
        --color-alert: #e3001b;
        --color-primary-hover: #5798a8;
        --color-bolder-hover: #437681;
        --color-secondary-hover: #eef2ff;
        --color-primary-muted: #6366f1;
        --color-secondary-muted: #6366f1;
        --color-green: #4b9855;
        --color-green-secondary: #008122;
        --color-dark-green: #296e32;
        --color-orange: #b75e05;

        --color-site-bg: #ffffff;
        --color-second-bg: #f3f3f3;
        --color-darker-bg: #d9d9d9;
        --color-site-bg-alternate: #f7f7f7;
        --color-dirt-white-bg: #f0f0f0;
        --color-red-bg: #e3001b;
        --color-red: #e3001b;
        --color-blue-bg: #437681;
        --color-soft-blue-bg: #8ab7c1;
        --color-gray-bg: #727272;

        --color-border-primary: #dad9d8;
        --color-border-primary-hover: #5798a8;
        --color-border-muted: #f4f4f4;
        --color-border-accent: #e91a38;
        --color-border-blue: #437681;
    }

    html {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        min-width: 320px;
    }
    h1 {
        @apply font-bold text-2xl leading-7 mb-4 sm:leading-8 sm:text-2xl md:text-3xl md:leading-10 lg:text-4xl lg:leading-[48px] lg:mb-7;
    }
    h2 {
        @apply text-xl font-bold leading-7 mb-3 sm:text-xl sm:leading-8 sm:mb-2 md:text-2xl md:leading-8 md:mb-3 lg:text-2xl lg:leading-8 lg:mb-4;
    }
    h3 {
        @apply text-lg font-bold leading-8;
    }
    h4,
    h5,
    h6 {
        @apply text-base font-bold leading-7;
    }
    p {
        @apply text-base leading-7 mb-4 mt-3 text-justify;
    }
    button {
        @apply focus:outline-none;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        // text-overflow: '';
        background: white;
    }
    input {
        @apply border rounded;
    }
    input[type='check'] {
        @apply w-5 h-5;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
    a {
        @apply text-skin-base hover:text-skin-primary-hover transition duration-150;
    }
    button {
        @apply transition duration-150;
    }
    .container-block {
        @apply xl:max-w-[1504px] xl:px-12 mx-auto my-0 px-5;
    }
    .content-row {
        @apply w-full px-4 py-4 sm:px-6 sm:py-6;
    }
    .color-grid {
        @apply w-full py-4 md:py-6 lg:py-7;
        &:nth-child(even) {
            @apply bg-skin-site-bg;
        }

        &:nth-child(odd) {
            @apply bg-skin-site-secondary-bg;
        }
    }
    .content-container {
        @apply xl:max-w-[1504px] xl:px-12 mx-auto my-0 px-5;
    }
    .row-title {
        @apply font-bold text-center text-xl leading-8  pb-4 md:text-2xl md:pb-6 lg:text-3xl lg:pb-8;
    }
    /* MegaMenu hover on Level One links */
    .level1:hover > .level1-link {
        @apply text-skin-primary-hover bg-white;
    }
    .site-max-width {
        @apply w-full;
        /* @apply max-w-7xl; */
    }
    .carousel {
        > .slick-list {
            @media (min-width: 640px) {
                margin-left: -12px;
                margin-right: -12px;
            }
            > .slick-track {
                > div {
                    @apply px-3;
                }
            }
        }
    }

    .topArrowsStyle,
    .topArrowsStyle.carousel,
    .topArrowsStyle.topsellerCarousel {
        .slick-next {
            background-color: transparent;
            top: -13px;
            right: -4px !important;

            &.slick-disabled {
                background: url('./icons/carousel-arrow-next-disabled.svg')
                    no-repeat center center !important;
                display: block !important;
                background-size: cover !important;
                background-color: transparent;
            }
        }

        .slick-prev {
            background-color: transparent;
            top: -13px;
            right: 40px !important;
            left: auto;

            &.slick-disabled {
                background: url('./icons/carousel-arrow-next-disabled.svg')
                    no-repeat center center !important;
                display: block !important;
                background-size: cover !important;
                background-color: transparent;
            }
        }
    }

    .topsellerCarousel {
        .slick-list {
            @media (min-width: 640px) {
                margin-left: -12px;
                margin-right: -12px;
            }
        }
    }

    .topsellerCarousel {
        .slick-list {
            @media (min-width: 640px) {
                margin-left: -12px;
                margin-right: -12px;
            }
        }
    }
}

.slick-slider {
    .slick-dots {
        position: absolute;
        bottom: 0;
        li {
            height: 6px;
            width: 6px;

            @media (min-width: 1024px) {
                height: 14px;
                width: 14px;
            }

            button {
                height: 6px;
                width: 6px;

                @media (min-width: 1024px) {
                    height: 14px;
                    width: 14px;
                }
            }
        }
    }
}

.activeDots {
    @apply pb-4 lg:pb-8;

    .slick-prev,
    .slick-next {
        margin-top: calc(-20px - 20px) !important;
    }
}

/* Product slider classes */
.image-gallery-image {
    object-fit: contain;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
    background: white;
    padding: 30px;
}
.image-gallery-content.fullscreen {
    background: rgb(247, 247, 247) !important;
}
.image-gallery-description {
    display: none;
}
.image-gallery-thumbnail-image {
    object-fit: contain;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
    background: white;
    padding: 10px;
}
.image-gallery-slide-wrapper {
    //padding-left: 20px;
    //padding-top: 4px;
}
.image-gallery-thumbnail {
    margin-bottom: 0px !important;
    border: 2px solid transparent;
    img {
        transition: all 0.5s ease;
        border: 4px solid white;
    }
}
.image-gallery-thumbnail:hover {
    border: 2px solid transparent;

    img {
        border: 2px solid var(--color-blue-bg);
    }
}
.image-gallery-thumbnail.active {
    border: 2px solid transparent !important;
    img {
        border: 2px solid var(--color-blue-bg);
    }
}
.image-gallery-thumbnail.active .image-gallery-thumbnail-image img {
    border: 4px solid red;
}
/* End product slider classes */

.navigation-shadow {
    box-shadow: rgb(0 0 0 / 9%) 0px 2px 24px 0px;
}
.counter,
.counter-wishlist {
    position: absolute;
    right: 0;
    top: 6px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    line-height: 16px;
    font-size: 11px;
    background-color: var(--color-secondary);
    color: var(--color-text-inverted);
    text-align: center;
}

.counter-wishlist {
    right: -6px;
}

.counter-desktop {
    color: var(--color-alert);
}

@layer components {
    /* #Mega Menu Styles
  if you use sass, convert all this additional css to tailwindcss using the 'hack' @apply for all element.style (css properties)
[ https://tailwindcss.com/docs/functions-and-directives/#apply ]
example:
  .mega-menu {
  @apply
  hidden
  left-0
  absolute
  text-left
  w-full;
  }
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
    .mega-menu {
        display: none;
        left: 0;
        position: absolute;
        text-align: left;
        width: 100%;
    }

    /* #hoverable Class Styles */
    .hoverable {
        position: static;
    }

    .hoverable:hover .mega-menu {
        display: block;
    }

    .styled-list {
        font-size: 16px;
        line-height: 23px;
        font-weight: normal;
        font-family: var(--secondary-font);
        color: var(--color-text-muted);

        li {
            padding-left: 22px;
            position: relative;

            &:before {
                content: '';

                display: block;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -9px;
                background: url('./icons/listCheck.svg') no-repeat center center;
                background-size: cover;
            }
        }
    }
    /* Product Page Classes */
    .product-page-name {
        @apply text-xl font-bold md:text-xl lg:text-2xl mb-0 md:mb-4;
    }
    .attributesList li::before {
        content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: var(--color-text-muted); /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        font-size: 20px;
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
    }

    .truncate-lines-3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .styled-button {
        @apply font-bold text-lg text-white p-2 bg-skin-blue-bg border border-skin-primary border-solid;
    }

    .styled-button,
    .pagebuilder-button-link,
    .pagebuilder-button-primary {
        @apply font-bold text-lg text-white p-2 bg-skin-blue-bg border border-skin-primary border-solid;
    }

    .styled-button:disabled {
        @apply font-bold text-lg text-white p-2 bg-skin-gray-bg border border-skin-primary border-solid;
    }

    /* #toggle Class Styles */

    /* .toggleable > label:after {
        content: '\25BC';
        font-size: 10px;
        padding-left: 6px;
        position: relative;
        top: -1px;
    }

    .toggle-input {
        display: none;
    }
    .toggle-input:not(checked) ~ .mega-menu {
        display: none;
    }

    .toggle-input:checked ~ .mega-menu {
        display: block;
    }

    .toggle-input:checked + label {
        color: white;
        background: rgb(3, 102, 114);
    }

    .toggle-input:checked ~ label:after {
        content: '\25B2';
        font-size: 10px;
        padding-left: 6px;
        position: relative;
        top: -1px;
    } */
}

.marken-button {
    .pagebuilder-button-primary {
        @apply block text-center;
    }
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: 0;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:after,
.slick-track:before {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    > div {
        outline-color: var(--color-blue-bg);

        a {
            outline-color: var(--color-blue-bg);
        }
    }
}
.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
    > div {
        height: 100%;
    }
}
.product-block-container {
    height: 100%;
    > div {
        height: 100%;
        > div {
            height: 100%;

            > a {
                height: 100%;

                // > div {
                //     height: 100%;
                // }
            }
        }
    }

    @media (min-width: 768px) {
        height: 100%;
    }
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */
.slick-loading .slick-list {
    background: #fff url('./icons/ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;
    margin-top: calc(-20px - 18px);

    display: block;

    width: 32px;
    height: 32px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background-size: cover;
    border-radius: 50%;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;

    @media (max-width: 1024px) {
        width: 32px;
        height: 32px;
        margin-top: calc(-16px - 14px);
    }
}
.slick-next {
    background: url('./icons/carousel-arrow-next.svg') no-repeat center center;
    -webkit-transform: rotate(160deg);
    -ms-transform: rotate(160deg);
    transform: rotate(360deg);
    background-size: 32px;
    background-color: var(--color-border-muted);

    @media (max-width: 1024px) {
        background-size: 26px;
    }

    &.slick-disabled {
        display: none !important;
    }
}
.slick-prev {
    background: url('./icons/carousel-arrow-next.svg') no-repeat center center;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    z-index: 1;
    background-size: 32px;
    background-color: var(--color-border-muted);

    @media (max-width: 1024px) {
        background-size: 26px;
    }
}

.slick-prev {
    left: 10px;

    &.slick-disabled {
        display: none !important;
    }
}

[dir='rtl'] .slick-prev {
    left: -20px;
    @media (min-width: 1280px) {
        left: -13px;
    }
    left: auto;
}

.slick-next {
    right: 10px;
}

[dir='rtl'] .slick-next {
    right: -20px;
    @media (min-width: 1280px) {
        right: -13px;
    }
    right: auto;
    left: 0px;
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li {
    position: relative;

    display: inline-block;

    width: 10px;
    height: 10px;
    margin: 0 2px;
    padding: 0;

    @media (min-width: 1024px) {
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
    }

    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 10px;
    height: 10px;
    padding: 2px;

    @media (min-width: 1024px) {
        width: 14px;
        height: 14px;
        padding: 2px;
    }

    cursor: pointer;

    color: transparent;
    outline: none;
    background: var(--color-darker-bg);
    border-radius: 50%;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li.slick-active button {
    background: var(--color-blue-bg);
}

.text-styled-button {
    @apply text-sm lg:text-xl w-full flex items-center justify-center mt-3;
    &:after {
        content: '';
        @apply block ml-2;
        display: block;
        width: 24px;
        height: 24px;
        background: url('./icons/arrow_right.svg') center center;
        background-size: cover;

        @media (max-width: 1024px) {
            width: 18px;
            height: 18px;
        }
    }
}

// .ceo-text {
//     @apply text-center;

//     p {
//         @apply text-lg;
//     }

//     > div {
//         @apply mb-8;
//     }

//     h1 {
//         @apply mb-6;
//     }
// }

.styled-list-secondary {
    li {
        @apply text-lg mb-1 pl-5 relative;

        &:before {
            content: '';
            width: 7px;
            height: 11px;
            margin-top: -50%;
            background: url('./icons/listArrowRight.svg') no-repeat center
                center;

            @apply absolute block left-0 top-1/2 -mt-1 bg-cover font-normal;
        }
    }
}

.address-card {
    @apply p-4 rounded-md bg-white text-base min-h-full;

    .icon-holder {
        @apply relative pl-6;
    }

    .phone {
        &::before {
            content: '';

            width: 19px;
            height: 19px;
            background: url('./icons/phone-icon.svg') no-repeat center center;
            background-size: cover;

            @apply block absolute top-0 left-0;
        }
    }

    .address {
        &::before {
            content: '';

            width: 16px;
            height: 25px;
            background: url('./icons/address_icon.svg') no-repeat center center;
            background-size: cover;
            @apply block absolute top-0 left-0;
        }
    }

    .working_hours {
        &::before {
            content: '';

            width: 19px;
            height: 19px;
            background: url('./icons/clock_dark.svg') no-repeat center center;
            background-size: cover;

            @apply block absolute top-0 left-0;
        }
    }
}

.delivery-separator {
    @apply text-center bg-skin-blue-bg text-white py-1 mt-10 text-lg;
}

.aspectRatioInherit {
    aspect-ratio: inherit;
}

.slideFigure {
    > span {
        aspect-ratio: inherit;
        > span {
            aspect-ratio: inherit;

            > img {
                aspect-ratio: inherit;
            }
        }
    }
}

//Product borders styles for mobile *
.mobile-borders-styles {
    > div:nth-child(even) {
        @media (max-width: 1023px) {
            border-right: none;
            border-bottom: none;
            border-left: none;
            margin-bottom: -1px;
        }
    }

    > div:nth-child(odd) {
        @media (max-width: 1023px) {
            border-left: none;
            border-bottom: none;
        }
    }

    > div:last-child {
        @media (max-width: 1023px) {
            border-bottom: 1px solid var(--color-border-muted);
            margin-bottom: 0;
        }
    }
    > div:nth-last-child(2) {
        @media (max-width: 1023px) {
            border-bottom: 1px solid var(--color-border-muted);
        }
    }
    > div:first-child {
        @media (max-width: 1023px) {
            border-top: none;
        }
    }
    > div:nth-child(2) {
        @media (max-width: 1023px) {
            border-top: none;
        }
    }
}

.hideScrollBar::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.hideDefaultCheckbox {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
}

.newsletterTextInputContainer {
    p {
        margin: 0;

        @media (min-width: 1280px) {
            width: 350px;
        }
    }
}
.readMoreContainer {
    display: block;
    position: relative;
    max-height: 396px;
    height: auto;
    overflow: hidden;
    -webkit-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;

    &.opened {
        max-height: 2000px;
    }
}

.readMoreBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    font-weight: 500;
    text-align: center;
}

.category-slider {
    .slick-track {
        display: flex;
        justify-content: center;

        .slick-slide {
            width: fit-content !important;
            min-width: 300px;
        }
    }
}

input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

input[type='radio']:checked + .radio::before {
    background: var(--color-blue-bg);
    box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 1);
}

input[type='radio']:disabled + .radio {
    & {
        color: var(--color-text-muted);
        &::before {
            opacity: 0.5;
        }
    }
}

.radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:before {
        content: '';
        width: 20px;
        height: 20px;
        display: block;
        padding: 4px;
        margin-right: 4px;
        border: solid 2px var(--color-blue-bg);
        border-radius: 50%;
    }
}

.image-gallery-fullscreen-button {
    &:hover {
        .image-gallery-svg {
            stroke: var(--color-blue-bg);
        }
    }
}

.cartContainer {
    height: calc(100% - 100px);
}

.sideCartProductXS {
    @media (min-width: 400px) {
        display: none;
    }
}

.sideCartProductSM {
    @media (min-width: 400px) {
        display: flex;
    }
}
.filterOptionHover:hover {
    color: var(--color-border-primary-hover);

    div {
        label {
            span {
                border-color: var(--color-border-primary-hover);
            }
        }
    }
}

.overflowWrapAnywhere {
    word-wrap: anywhere;
}

input[type='text']:-webkit-autofill + label,
input[type='email']:-webkit-autofill + label,
input[type='password']:-webkit-autofill + label,
input[type='select']:-webkit-autofill + label {
    @apply absolute transition-all -top-2 text-xs pointer-events-none bg-white px-2 text-gray-400 rounded;
}

//TABLE STYLES
.tableCustom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    // @media (max-width: 700px) {
    //     &:not(.noResponsive) {
    //         .tableRow {
    //             flex-direction: column;
    //         }

    //         .tableCol {
    //             width: 100% !important;
    //         }
    //     }
    // }
}

.tableRow {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.tableCol {
    display: flex;
    padding: 10px;
    align-items: center;
    font-size: 16px;
    text-align: left;
    word-break: break-all;
    height: 60px;

    &.noHeight {
        height: auto !important;
    }
}

.tableHead,
.tableBody {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.tableHead {
    background: var(--bg-red-btn);
    .tableCol {
        color: #fff;
        font-weight: bold;
    }
}

.tableBody {
    .tableRow:nth-child(odd) {
        background: var(--color-site-bg-alternate);
    }

    .tableRow:nth-child(even) {
        background: white;
    }
}

.columns-1 {
    .tableCol {
        width: 100%;
    }
}
.columns-2 {
    .tableCol {
        width: 50%;
    }
}
.columns-3 {
    .tableCol {
        width: 33.33%;
    }
}
.columns-4 {
    .tableCol {
        width: 25%;
    }
}
.columns-5 {
    .tableCol {
        width: 20%;
    }
}
.columns-6 {
    .tableCol {
        width: 16.66%;
    }
}
.columns-7 {
    .tableCol {
        width: 14.29%;
    }
}
.columns-8 {
    .tableCol {
        width: 12.5%;
    }
}
.columns-9 {
    .tableCol {
        width: 11.11%;
    }
}
.columns-10 {
    .tableCol {
        width: 10%;
    }
}
//END TABLE STYLES

.smarthomeTabItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    margin: 0 auto;
    border-radius: 50%;
    background: var(--color-site-bg-alternate);
    transition: all 0.5s ease;

    @media (max-width: 991px) {
        width: 150px;
        height: 150px;
        padding: 10px;
    }

    @media (max-width: 681px) {
        width: 80px;
        height: 80px;
        padding: 5px;
    }

    @media (max-width: 400px) {
        width: 60px;
        height: 60px;
        padding: 5px;
    }

    > img {
        max-width: 100px;
        transition: all 0.5s ease;

        @media (max-width: 1200px) {
            width: 100%;
        }
    }
}

[aria-selected='true'] {
    background: var(--gray-bg);
    position: relative;

    .smarthomeTabItem {
        border-radius: 0;
    }

    &:before {
        content: '';
        position: absolute;
        top: calc(100% + 0px);
        left: 50%;
        margin-left: -20px;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;

        border-top: 20px solid var(--color-site-bg-alternate);

        @media (max-width: 991px) {
            margin-left: -10px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            border-top: 15px solid var(--color-site-bg-alternate);
            width: 20px !important;
        }
    }
}

.rc-slider-handle-dragging {
    .rcSliderVal {
        display: block;
    }
}

.hyphensAuto {
    hyphens: auto;
}

.checkboxLabel {
    @apply text-xs text-left mb-2;
    a {
        color: var(--color-alert);
    }
}

.quantityArrow {
    @apply relative;
    &:after {
        content: '';
        @apply w-2 h-2 absolute -mt-1 top-1/2 right-7 pointer-events-none block;
        background: url(./icons/quantityArrowIcon.svg) no-repeat center center;
    }
}

.pagebuilder-carousel {
    @apply mb-6;
    > div {
        @apply mx-3 md:mx-0 w-2/3 sm:w-2/3 md:w-auto block flex-[0_0_auto] md:flex-none;
    }
}

//Page builder grid styles
.pagebuilder-column-line {
    @apply flex flex-wrap flex-row -mx-4 -mb-8;
}

.empty-column {
    margin-bottom: 0 !important;
}

.pagebuilder-column {
    @apply px-4 mb-8;

    flex: 1 1 auto;
    width: 100%;

    @media (min-width: 768px) {
        width: 50%;
    }
}

.w-1of3 {
    width: 100%;
    @media (min-width: 768px) {
        width: 33.33%;
    }
}

.w-1of4 {
    width: 100%;
    @media (min-width: 640px) {
        width: 50%;
    }

    @media (min-width: 1024px) {
        width: 25%;
    }
}

.w-1of5 {
    // @apply lg:text-white;
    width: 100%;
    @media (min-width: 640px) {
        width: 50%;
    }

    @media (min-width: 1024px) {
        width: 20%;
    }
}

.w-1of6 {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 16.666667%;
    }
}

.w-1of7 {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 14.2857142857%;
    }
}

.w-1of8 {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 12.5%;
    }
}

.w-1of9 {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 11.11%;
    }
}

.w-1of10 {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 10%;
    }
}

.w-1of11 {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 9.09090909091%;
    }
}

.w-1of12 {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 8.33333333333%;
    }
}

.w-10-per {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 10%;
    }
}
.w-20-per {
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 20%;
    }
}
.w-25-per {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 25%;
    }
}
.w-30-per {
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 30%;
    }
}
.w-40-per {
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 40%;
    }
}
.w-60-per {
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 60%;
    }
}

.w-70-per {
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 70%;
    }
}
.w-75-per {
    width: 100%;
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 75%;
    }
}
.w-80-per {
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 80%;
    }
}
.w-90-per {
    @media (min-width: 475px) {
        width: 50%;
    }
    @media (min-width: 768px) {
        width: 33.33%;
    }
    @media (min-width: 1024px) {
        width: 90%;
    }
}

.no-less-than-2 {
    @media (max-width: 640px) {
        width: 50% !important;
    }
}

.no-less-than-3 {
    @media (max-width: 768px) {
        width: 33.33% !important;
    }
}

.no-less-than-4 {
    @media (max-width: 768px) {
        width: 25% !important;
    }
}

//End page builder grid styles

.h-full-children {
    > div {
        @apply h-full;
    }
}

.clear-margin-top {
    margin-top: 0 !important;
}

.pagebuilder-poster-content {
    @apply absolute right-0 xs:right-1/2 xs:translate-x-2/4 md:transform-none md:right-0 bottom-4 md:bottom-0 flex flex-col xs:items-start md:items-end justify-end font-extrabold;

    > div {
        @apply flex items-end flex-col;

        > p {
            @apply m-0 inline-block;
            &:first-child {
                @apply block bg-white text-base md:text-3xl text-skin-red p-2 md:p-4;
            }

            &:last-child {
                @apply relative block text-white bg-skin-gray-bg p-2 md:p-4 text-sm md:text-2xl font-semibold text-left pr-8 md:pr-16;

                &:after {
                    content: '';

                    width: 32px;
                    height: 32px;
                    display: block;
                    position: absolute;
                    top: 16px;
                    right: 14px;
                    background: url(./icons/arrow_right_white.svg) no-repeat
                        center center;

                    @media (max-width: 768px) {
                        width: 24px;
                        height: 24px;
                        top: 6px;
                        right: 4px;
                    }
                }
            }
        }
    }
}

.image-gallery-bullets {
    bottom: 0;
    @media (max-width: 640px) {
        position: absolute;
        bottom: 5px;
    }

    .image-gallery-bullets-container {
        .image-gallery-bullet {
            height: 4px !important;
            width: 20px !important;
            margin: 0 5px;
            padding: 0;
            box-shadow: none;
            background-color: #9b9e9f;
            border: none !important;
            border-radius: 0;
            transition: none;

            &.active {
                background-color: var(--color-blue-bg);
                transform: none;
            }
        }
    }
}

.image-gallery-full-width {
    .image-gallery-slide-wrapper {
        width: 100% !important;
    }
}

.ios {
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='number'],
    input[type='tel'],
    textarea,
    select {
        font-size: 16px !important;
    }
}
// Pagebuilder classes

[data-content-type='row'] {
    @apply content-container;

    [data-element='inner'] {
        @apply mt-12;
    }
    .content-container {
        padding: 0 !important;
        margin: 0 !important;
    }
}
.content-container {
    [data-content-type='row'] {
        padding: 0 !important;
    }
}

.no-margin {
    margin: 0 !important;
}

// Mobile and Desktop image block
[data-element='mobile_image'],
.pagebuilder-mobile-only {
    @apply block md:hidden;
}
[data-element='desktop_image'],
.pagebuilder-mobile-hidden {
    @apply hidden md:block;
}
.w-40percent {
    @apply w-16 sm:w-24;
}

.circled_img {
    //height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
        border-radius: 50%;
    }
}

.jobsTable {
    border-collapse: separate;
    border-spacing: 0 20px;
}

.jobs_managers {
    padding: 0 30px;
    flex-direction: column;

    > img {
        margin-bottom: 20px;
        max-width: 192px !important;

        border: 1px solid var(--jobs-yellow-color);
    }

    > strong {
        margin-bottom: 5px;

        text-align: center;
        font-weight: 600;
        font-size: 14px;
    }

    > span {
        margin-bottom: 5px;

        text-align: center;
        font-weight: normal;
        font-size: 14px;
    }
}

.jobsListHeadline {
    @apply mb-4 font-semibold;
}

.jobDescription {
    @apply grid grid-cols-2 gap-8;

    .col-12 {
        @apply col-span-2;
    }

    .col-sm-6 {
        @apply col-span-2 sm:col-span-1;
    }

    .styled_list {
        @apply mb-4 text-black ml-5;

        li {
            @apply mb-2;
        }
    }

    p {
        @apply text-black;
    }
}

.jobs_progress_scheme {
    .jobs_progress_scheme_item {
        padding-top: 40px;
        padding-bottom: 15px;

        @media (min-width: 300px) and (max-width: 1366px) {
            & {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    .start,
    .middle,
    .finish {
        position: relative;

        .date {
            width: 79px;
            height: 79px;
            display: flex;
            align-items: center;
            justify-content: center;

            color: #fff;
            font-size: 22px;
            line-height: 26px;
            border-radius: 50%;
            font-weight: normal;
            background: #707070;
            font-family: var(--jobs-font);
        }

        p {
            padding-top: 18px;
            position: relative;

            font-size: 14px;
            line-height: 16px;
            font-weight: normal;
            text-align: left;
        }
    }

    .start {
        p {
            &:before {
                content: '';

                display: block;
                width: 25px;
                height: 65px;
                position: absolute;
                left: 60px;
                bottom: -60px;

                background: url('./icons/long_arrow_down_right.svg') no-repeat
                    center center;
                background-size: cover;
            }
        }

        @media (min-width: 404px) and (max-width: 995px) {
            & {
                p {
                    max-width: 360px;
                }
            }
        }
    }

    .start,
    .finish {
        p {
            padding-left: 86px;
            padding-bottom: 13px;
        }

        .date {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .middle {
        margin-right: 140px;
        p {
            padding-right: 84px;
            padding-top: 47px;

            text-align: right;

            &:before {
                content: '';

                display: block;
                width: 25px;
                height: 65px;
                position: absolute;
                left: calc(100% - 13px);
                bottom: -74px;

                background: url('./icons/long_arrow_down_left.svg') no-repeat
                    center center;
                background-size: cover;
            }
        }

        .date {
            position: absolute;
            top: 24px;
            right: 0;

            background: var(--jobs-yellow-color);
        }

        @media (min-width: 300px) and (max-width: 500px) {
            margin-right: 20px !important;
        }

        @media (min-width: 998px) and (max-width: 1200px) {
            margin-right: 70px;
        }
    }

    .finish {
        margin-left: 85px;
        margin-top: 60px;

        @media (min-width: 300px) and (max-width: 400px) {
            & {
                margin-left: 65px;
            }
        }
    }
}
video {
    width: 100%;
}
// .pagebuilder-video-container,
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.pagebuilder-column h3 {
    margin-top: 10px;
}
.pagebuilder-video-container iframe,
.video-container iframe,
.pagebuilder-video-container object,
.video-container object,
.pagebuilder-video-container embed,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pagebuilder-video-inner {
    margin-top: 0 !important;
}

// Pagebuilder product card
.price-final_price {
    @apply flex flex-col-reverse;
}
.price-box .special-price,
.price-box .price-final_price .price {
    @apply flex flex-col items-end text-lg font-bold md:text-xl  text-skin-red;
}
.price-box .old-price .price {
    @apply text-xs text-skin-muted flex md:text-base font-normal line-through;
}

.product-card-image .product-image-container {
    @apply w-full bg-white aspect-w-1 aspect-h-1 overflow-hidden group-hover:opacity-75 h-full block;
}
.product-card-image .product-image-container .product-image-wrapper {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    inset: 0px;
}

.product-card-image
    .product-image-container
    .product-image-wrapper
    .product-image-photo {
    max-width: 90%;
    min-width: 90%;
    max-height: 90%;
    min-height: 90%;
    margin-left: 5%;
    margin-top: 5%;
    object-position: center;
    object-fit: contain;
    @apply object-center object-contain;
}

.product-image-photo {
    @apply mr-auto ml-auto;
}

.product-block-container {
    > div {
        height: 100%;
        > div {
            height: 100%;

            > a {
                height: 100%;

                // > div {
                //     height: 100%;
                // }
            }
        }
    }

    @media (min-width: 768px) {
        height: 100%;
    }
}

.shortenText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

[data-appearance='full-width']:not(.image-auto) {
    > img {
        @apply w-full;
    }
}

.image-width-auto {
    > img {
        @apply w-auto;
    }
}

.image-width-auto.centered-image {
    > img {
        @apply w-auto mx-auto;
    }
}

button#code,
button#code3,
button#code2 {
    position: relative;
    background: white;
    color: var(--color-primary);
    padding: 0 26px 0 4px;
    border-radius: 5px;
    z-index: 1;
}

button#code:before,
button#code3:before,
button#code2:before {
    content: '';

    width: 20px;
    height: 20px;
    background: red;
    position: absolute;
    right: 4px;
    top: 2px;
    background: url(./icons/copy.svg) no-repeat center center;
}

.storeProspectsGridCol {
    @media (min-width: 769px) and (max-width: 1280px) {
        display: block;
        grid-column: span 6 / span 6;
    }
}

.pagebuilder-slider {
    img {
        width: 100%;
    }
}

.black-week-btn {
    > a {
        @apply font-normal text-base md:text-lg text-white p-2 bg-[#e6007f]  border border-[#e6007f]  border-solid hover:text-white;
    }
}

.filtersSticky {
    .sticky {
        top: 48px !important;
    }
}
.image-gallery-bullets {
    bottom: 0;
    @media (max-width: 640px) {
        position: absolute;
        bottom: 5px;
    }

    .image-gallery-bullets-container {
        .image-gallery-bullet {
            height: 4px !important;
            width: 20px !important;
            margin: 0 5px;
            margin-top: 5px;
            padding: 0;
            box-shadow: none;
            background-color: #9b9e9f;
            border: none !important;
            border-radius: 0;
            transition: none;

            &.active {
                background-color: var(--color-red);
                transform: none;
            }
        }
    }
}

.product-page-wishlist-btn {
    button {
        position: initial;
    }
}

.image-gallery-full-width {
    .image-gallery-slide-wrapper {
        width: 100% !important;
    }
}

.wordBreak {
    word-break: break-word;
}

.border-product-card {
    border-color: var(--color-border-primary);
}

.mobileScrollTarget {
    scroll-margin-top: 70px;
}
