.productName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 150px;
    line-height: 20px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
}

.highlight {
    background: none;
    padding: 0;
    color: red;
    font-weight: bold;
}
